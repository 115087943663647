import React, { useState, useEffect, useRef }  from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'lucide-react';
import './styles/navbar.css';

const Navbar = () => {

	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		function loginChecker() {
		  fetch('/api/login/isLoggedIn')
			.then((response) => response.json())
			.then((response) => {
			  setIsLoggedIn(response.loggedIn);
			});
		}
	
		loginChecker();
	}, []);

	const navLinksRef = useRef(null);

	function menuIconOnClick() {
		console.log(navLinksRef.current.style.display);
		if( navLinksRef.current.style.display === 'none' || navLinksRef.current.style.display === "" ){
			navLinksRef.current.style.width = '100%';
			navLinksRef.current.style.display = 'block';
		}else{
			navLinksRef.current.style.display = 'none';
		}
	}

    return (
		<nav>
			<div className="navbar">

				<div className="navbar-company-name">
					<Menu className="menu-icon" onClick={menuIconOnClick}/>
					<div className='company-name'>
						<Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
							<strong>The Bommber</strong>
						</Link>
					</div>
				</div>

				<div ref={navLinksRef} className="nav-links" >
					<div className="link">
						<Link to="/about-us" style={{ textDecoration: 'none', color: 'white' }}>
							<strong>About Us</strong>
						</Link>
					</div>
					<div className="link">
						<a href="/pricing.html" style={{ textDecoration: 'none', color: 'white' , fontWeight:'bold'}} alt="Pricing Page">Pricing</a>
						{/* <Link to="/pricing.html" style={{ textDecoration: 'none', color: 'white' }}>
							<strong>Pricing</strong>
						</Link> */}
					</div>
					<div className="link">
						{	isLoggedIn ? (
								<>
									<a href="/profile.html" style={{ textDecoration: 'none', color: 'white', fontWeight:'bold' }} alt="Profile Page" >Profile</a>
								</>
								) : (
								<>
									<a href="/login.html" style={{ textDecoration: 'none', color: 'white', fontWeight:'bold' }} alt="Login Page" >Log In</a>
								</>
								)
						}
					</div>
				</div>
			</div>
		</nav>
    );
};

export default Navbar;