import React from 'react';
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import { Link } from 'react-router-dom';

import "./styles/error-404.css"

const Error404 = () => {
	return (
		<>
			<Navbar />
			<div className="content-404">

				<div className="page-title">Error 404</div>
				<div className="page-content">Oop! The page you are looking for can't be found</div>

				<button><Link to="/" style={{textDecoration:'none'}}>Go Home</Link></button>
			</div>
			<Footer />
		</>
	)
}

export default Error404;